import { useTranslation } from 'next-i18next';
import { Heading } from '@indriver/nova';
import { CitiesChips, ICityInfo } from 'entities/city';

interface ICrosslinkCitiesProps {
    cities: ICityInfo[];
}

export const CrosslinkCities = ({ cities }: ICrosslinkCitiesProps) => {
    const { t } = useTranslation();
    return (
        <section>
            <Heading type='h2' size='xxl' margin='m'>
                {t('widgets.crosslink_cities.title')}
            </Heading>
            <CitiesChips cities={cities} buildLink={(cityInfo) => `/${cityInfo.citySlug}`} />
        </section>
    );
};
