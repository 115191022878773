import { useTranslation } from 'next-i18next';
import { Heading } from '@indriver/nova';
import { ChipLink, ChipLinks } from 'common/ui';
import { getDomains, useDomainInfo } from 'entities/domain';

export const CrosslinkCountries = () => {
    const { t } = useTranslation();
    const { countryIso } = useDomainInfo();
    const domains = getDomains();
    return (
        <section>
            <Heading type='h2' size='xxl' margin='m'>
                {t('widgets.crosslink_countries.title')}
            </Heading>
            <ChipLinks>
                {domains
                    .filter((domain) => domain.countryIso !== countryIso)
                    .map((domain) => (
                        <ChipLink key={domain.countryIso} href={`https://${domain.domain}`}>
                            {t(`common.countries.${domain.countryIso}`)}
                        </ChipLink>
                    ))}
            </ChipLinks>
        </section>
    );
};
